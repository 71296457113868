/**
 * Object representation of the chunk section of a MIDI file.
 * @param {object} fields - {type: number, data: array, size: array}
 * @return {Chunk}
 */
class Chunk {
	constructor(fields) {
		this.type = fields.type;
		this.data = fields.data;
		this.size = [0, 0, 0, fields.data.length];
	}
}

export {Chunk};
/**
 * MIDI file format constants, including note -> MIDI number translation.
 * @return {Constants}
 */

var Constants = {
	VERSION					: '1.5.2',
	HEADER_CHUNK_TYPE  		: [0x4d, 0x54, 0x68, 0x64], // Mthd
	HEADER_CHUNK_LENGTH  	: [0x00, 0x00, 0x00, 0x06], // Header size for SMF
	HEADER_CHUNK_FORMAT0    : [0x00, 0x00], // Midi Type 0 id
	HEADER_CHUNK_FORMAT1    : [0x00, 0x01], // Midi Type 1 id
	HEADER_CHUNK_DIVISION   : [0x00, 0x80], // Defaults to 128 ticks per beat
	TRACK_CHUNK_TYPE		: [0x4d, 0x54, 0x72, 0x6b], // MTrk,
	META_EVENT_ID			: 0xFF,
	META_TEXT_ID			: 0x01,
	META_COPYRIGHT_ID		: 0x02,
	META_TRACK_NAME_ID		: 0x03,
	META_INSTRUMENT_NAME_ID : 0x04,
	META_LYRIC_ID			: 0x05,
	META_MARKER_ID			: 0x06,
	META_CUE_POINT			: 0x07,
	META_TEMPO_ID			: 0x51,
	META_SMTPE_OFFSET		: 0x54,
	META_TIME_SIGNATURE_ID	: 0x58,
	META_KEY_SIGNATURE_ID	: 0x59,
	META_END_OF_TRACK_ID	: [0x2F, 0x00],
	CONTROLLER_CHANGE_STATUS: 0xB0, // includes channel number (0)
	PROGRAM_CHANGE_STATUS	: 0xC0, // includes channel number (0)
};

export {Constants};
/**
 * Holds all data for a "controller change" MIDI event
 * @param {object} fields {controllerNumber: integer, controllerValue: integer}
 * @return {ControllerChangeEvent}
 */
class ControllerChangeEvent {
	constructor(fields) {
		this.type = 'controller';
		// delta time defaults to 0.
		this.data = Utils.numberToVariableLength(0x00).concat(Constants.CONTROLLER_CHANGE_STATUS, fields.controllerNumber, fields.controllerValue);
	}
}

export {ControllerChangeEvent};
/**
 * Object representation of a meta event.
 * @param {object} fields - type, data
 * @return {MetaEvent}
 */
class MetaEvent {
	constructor(fields) {
		this.type = 'meta';
		this.data = Utils.numberToVariableLength(0x00);// Start with zero time delta
		this.data = this.data.concat(Constants.META_EVENT_ID, fields.data);
	}
}

export {MetaEvent};
/**
 * Wrapper for noteOnEvent/noteOffEvent objects that builds both events.
 * @param {object} fields - {pitch: '[C4]', duration: '4', wait: '4', velocity: 1-100}
 * @return {NoteEvent}
 */
class NoteEvent {
	constructor(fields) {
		this.type 		= 'note';
		this.pitch 		= Utils.toArray(fields.pitch);
		this.wait 		= fields.wait || 0;
		this.duration 	= fields.duration;
		this.sequential = fields.sequential || false;
		this.velocity 	= fields.velocity || 50;
		this.channel 	= fields.channel || 1;
		this.repeat 	= fields.repeat || 1;
		this.velocity 	= this.convertVelocity(this.velocity);
		this.grace		= fields.grace;
		this.buildData();
	}

	/**
	 * Builds int array for this event.
	 * @return {NoteEvent}
	 */
	buildData() {
		this.data = [];

		var tickDuration = this.getTickDuration(this.duration, 'note');
		var restDuration = this.getTickDuration(this.wait, 'rest');

		// Apply grace note(s) and subtract ticks (currently 1 tick per grace note) from tickDuration so net value is the same
		if (this.grace) {
			let graceDuration = 1;
			this.grace = Utils.toArray(this.grace);
			this.grace.forEach(function(pitch) {
				let noteEvent = new NoteEvent({pitch:this.grace, duration:'T' + graceDuration});
				this.data = this.data.concat(noteEvent.data)

				tickDuration -= graceDuration;
			}, this);
		}

		// fields.pitch could be an array of pitches.
		// If so create note events for each and apply the same duration.
		var noteOn, noteOff;
		if (Array.isArray(this.pitch)) {
			// By default this is a chord if it's an array of notes that requires one NoteOnEvent.
			// If this.sequential === true then it's a sequential string of notes that requires separate NoteOnEvents.
			if ( ! this.sequential) {
				// Handle repeat
				for (var j = 0; j < this.repeat; j++) {
					// Note on
					this.pitch.forEach(function(p, i) {
						if (i == 0) {
							noteOn = new NoteOnEvent({data: Utils.numberToVariableLength(restDuration).concat(this.getNoteOnStatus(), Utils.getPitch(p), this.velocity)});

						} else {
							// Running status (can ommit the note on status)
							noteOn = new NoteOnEvent({data: [0, Utils.getPitch(p), this.velocity]});
						}

						this.data = this.data.concat(noteOn.data);
					}, this);

					// Note off
					this.pitch.forEach(function(p, i) {
						if (i == 0) {
							noteOff = new NoteOffEvent({data: Utils.numberToVariableLength(tickDuration).concat(this.getNoteOffStatus(), Utils.getPitch(p), this.velocity)});

						} else {
							// Running status (can ommit the note off status)
							noteOff = new NoteOffEvent({data: [0, Utils.getPitch(p), this.velocity]});
						}

						this.data = this.data.concat(noteOff.data);
					}, this);
				}

			} else {
				// Handle repeat
				for (var j = 0; j < this.repeat; j++) {
					this.pitch.forEach(function(p, i) {
						// restDuration only applies to first note
						if (i > 0) {
							restDuration = 0;
						}

						// If duration is 8th triplets we need to make sure that the total ticks == quarter note.
						// So, the last one will need to be the remainder
						if (this.duration === '8t' && i == this.pitch.length - 1) {
							let quarterTicks = Utils.numberFromBytes(Constants.HEADER_CHUNK_DIVISION);
							tickDuration = quarterTicks - (tickDuration * 2);
						}

						noteOn = new NoteOnEvent({data: Utils.numberToVariableLength(restDuration).concat([this.getNoteOnStatus(), Utils.getPitch(p), this.velocity])});
						noteOff = new NoteOffEvent({data: Utils.numberToVariableLength(tickDuration).concat([this.getNoteOffStatus(), Utils.getPitch(p), this.velocity])});

						this.data = this.data.concat(noteOn.data, noteOff.data);
					}, this);
				}
			}

			return this;
		}

		throw 'pitch must be an array.';
	};

	/**
	 * Converts velocity to value 0-127
	 * @param {number} velocity - Velocity value 1-100
	 * @return {number}
	 */
	convertVelocity(velocity) {
		// Max passed value limited to 100
		velocity = velocity > 100 ? 100 : velocity;
		return Math.round(velocity / 100 * 127);
	};

	/**
	 * Gets the total number of ticks based on passed duration.
	 * Note: type=='note' defaults to quarter note, type==='rest' defaults to 0
	 * @param {(string|array)} duration
	 * @param {string} type ['note', 'rest']
	 * @return {number}
	 */
	getTickDuration(duration, type) {
		if (Array.isArray(duration)) {
			// Recursively execute this method for each item in the array and return the sum of tick durations.
			return duration.map(function(value) {
				return this.getTickDuration(value, type);
			}, this).reduce(function(a, b) {
				return a + b;
			}, 0);
		}

		duration = duration.toString();

		if (duration.toLowerCase().charAt(0) === 't') {
			// If duration starts with 't' then the number that follows is an explicit tick count
			return parseInt(duration.substring(1));
		}

		// Need to apply duration here.  Quarter note == Constants.HEADER_CHUNK_DIVISION
		// Rounding only applies to triplets, which the remainder is handled below
		var quarterTicks = Utils.numberFromBytes(Constants.HEADER_CHUNK_DIVISION);
		return Math.round(quarterTicks * this.getDurationMultiplier(duration, type));
	}

	/**
	 * Gets what to multiple ticks/quarter note by to get the specified duration.
	 * Note: type=='note' defaults to quarter note, type==='rest' defaults to 0
	 * @param {string} duration
	 * @param {string} type ['note','rest']
	 * @return {number}
	 */
	getDurationMultiplier(duration, type) {
		// Need to apply duration here.  Quarter note == Constants.HEADER_CHUNK_DIVISION
		switch (duration) {
			case '0':
				return 0;
			case '1':
				return 4;
			case '2':
				return 2;
			case 'd2':
				return 3;
			case '4':
				return 1;
			case '4t':
				return 0.666;
			case 'd4':
				return 1.5;
			case '8':
				return 0.5;
			case '8t':
				// For 8th triplets, let's divide a quarter by 3, round to the nearest int, and substract the remainder to the last one.
				return 0.33;
			case 'd8':
				return 0.75;
			case '16':
				return 0.25;
			case '16t':
				return 0.166;
			case '32':
				return 0.125;
			case '64':
				return 0.0625;
			default:
				// Notes default to a quarter, rests default to 0
				//return type === 'note' ? 1 : 0;
		}

		throw duration + ' is not a valid duration.';
	};

	/**
	 * Gets the note on status code based on the selected channel. 0x9{0-F}
	 * Note on at channel 0 is 0x90 (144)
	 * 0 = Ch 1
	 * @return {number}
	 */
	getNoteOnStatus() {return 144 + this.channel - 1}

	/**
	 * Gets the note off status code based on the selected channel. 0x8{0-F}
	 * Note off at channel 0 is 0x80 (128)
	 * 0 = Ch 1
	 * @return {number}
	 */
	getNoteOffStatus() {return 128 + this.channel - 1}
}

export {NoteEvent};
/**
 * Holds all data for a "note off" MIDI event
 * @param {object} fields {data: []}
 * @return {NoteOffEvent}
 */
class NoteOffEvent {
	constructor(fields) {
		this.data = fields.data;
	}
}

export {NoteOffEvent};
/**
 * Holds all data for a "note on" MIDI event
 * @param {object} fields {data: []}
 * @return {NoteOnEvent}
 */
class NoteOnEvent {
	constructor(fields) {
		this.data = fields.data;
	}
}

export {NoteOnEvent};
/**
 * Holds all data for a "program change" MIDI event
 * @param {object} fields {instrument: integer}
 * @return {ProgramChangeEvent}
 */
class ProgramChangeEvent {
	constructor(fields) {
		this.type = 'program';
		// delta time defaults to 0.
		this.data = Utils.numberToVariableLength(0x00).concat(Constants.PROGRAM_CHANGE_STATUS, fields.instrument);
	}
}

export {ProgramChangeEvent};
/**
 * Holds all data for a track.
 * @param {object} fields {type: number, data: array, size: array, events: array}
 * @return {Track}
 */
class Track {
	constructor() {
		this.type = Constants.TRACK_CHUNK_TYPE;
		this.data = [];
		this.size = [];
		this.events = [];
	}

	/**
	 * Adds any event type to the track.
	 * @param {(NoteEvent|MetaEvent|ProgramChangeEvent)} event - Event object.
	 * @param {function} mapFunction - Callback which can be used to apply specific properties to all events. 
	 * @return {Track}
	 */
	addEvent(event, mapFunction) {
		if (Array.isArray(event)) {
			event.forEach(function(e, i) {
				// Handle map function if provided
				if (typeof mapFunction === 'function' && e.type === 'note') {
					var properties = mapFunction(i, e);

					if (typeof properties === 'object') {
						for (var j in properties) {
							switch(j) {
								case 'duration':
									e.duration = properties[j];
									break;
								case 'sequential':
									e.sequential = properties[j];
									break;
								case 'velocity':
									e.velocity = e.convertVelocity(properties[j]);
									break;
							}
						}		

						// Gotta build that data
						e.buildData();
					}
				}

				this.data = this.data.concat(e.data);
				this.size = Utils.numberToBytes(this.data.length, 4); // 4 bytes long
				this.events.push(e);
			}, this);

		} else {
			this.data = this.data.concat(event.data);
			this.size = Utils.numberToBytes(this.data.length, 4); // 4 bytes long
			this.events.push(event);
		}

		return this;
	}

	/**
	 * Sets tempo of the MIDI file.
	 * @param {number} bpm - Tempo in beats per minute.
	 * @return {Track}
	 */
	setTempo(bpm) {
		var event = new MetaEvent({data: [Constants.META_TEMPO_ID]});
		event.data.push(0x03); // Size
		var tempo = Math.round(60000000 / bpm);
		event.data = event.data.concat(Utils.numberToBytes(tempo, 3)); // Tempo, 3 bytes
		return this.addEvent(event);
	}

	/**
	 * Sets time signature.
	 * @param {number} numerator - Top number of the time signature.
	 * @param {number} denominator - Bottom number of the time signature.
	 * @param {number} midiclockspertick - Defaults to 24.
	 * @param {number} notespermidiclock - Defaults to 8.
	 * @return {Track}
	 */
	setTimeSignature(numerator, denominator, midiclockspertick, notespermidiclock) {
		midiclockspertick = midiclockspertick || 24;
		notespermidiclock = notespermidiclock || 8;
		
		var event = new MetaEvent({data: [Constants.META_TIME_SIGNATURE_ID]});
		event.data.push(0x04); // Size
		event.data = event.data.concat(Utils.numberToBytes(numerator, 1)); // Numerator, 1 bytes
		
		var _denominator = Math.log2(denominator);	// Denominator is expressed as pow of 2
		event.data = event.data.concat(Utils.numberToBytes(_denominator, 1)); // Denominator, 1 bytes
		event.data = event.data.concat(Utils.numberToBytes(midiclockspertick, 1)); // MIDI Clocks per tick, 1 bytes
		event.data = event.data.concat(Utils.numberToBytes(notespermidiclock, 1)); // Number of 1/32 notes per MIDI clocks, 1 bytes
		return this.addEvent(event);
	}

	/**
	 * Sets key signature.
	 * @param {*} sf - 
	 * @param {*} mi -
	 * @return {Track}
	 */
	setKeySignature(sf, mi) {
		var event = new MetaEvent({data: [Constants.META_KEY_SIGNATURE_ID]});
		event.data.push(0x02); // Size

		var mode = mi || 0;
		sf = sf || 0;

		//	Function called with string notation
		if (typeof mi === 'undefined') {
			var fifths = [
				['Cb', 'Gb', 'Db', 'Ab', 'Eb', 'Bb', 'F', 'C', 'G', 'D', 'A', 'E', 'B', 'F#', 'C#'],
				['ab', 'eb', 'bb', 'f', 'c', 'g', 'd', 'a', 'e', 'b', 'f#', 'c#', 'g#', 'd#', 'a#']
			];
			var _sflen = sf.length;
			var note = sf || 'C';

			if (sf[0] === sf[0].toLowerCase()) mode = 1

			if (_sflen > 1) {
				switch (sf.charAt(_sflen - 1)) {
					case 'm':
						mode = 1;
						note = sf.charAt(0).toLowerCase();
						note = note.concat(sf.substring(1, _sflen - 1));
						break;
					case '-':
						mode = 1;
						note = sf.charAt(0).toLowerCase();
						note = note.concat(sf.substring(1, _sflen - 1));
						break;
					case 'M':
						mode = 0;
						note = sf.charAt(0).toUpperCase();
						note = note.concat(sf.substring(1, _sflen - 1));
						break;
					case '+':
						mode = 0;
						note = sf.charAt(0).toUpperCase();
						note = note.concat(sf.substring(1, _sflen - 1));
						break;
				}
			}

			var fifthindex = fifths[mode].indexOf(note);
			sf = fifthindex === -1 ? 0 : fifthindex - 7;
		}

		event.data = event.data.concat(Utils.numberToBytes(sf, 1)); // Number of sharp or flats ( < 0 flat; > 0 sharp)
		event.data = event.data.concat(Utils.numberToBytes(mode, 1)); // Mode: 0 major, 1 minor
		return this.addEvent(event);
	}

	/**
	 * Adds text to MIDI file.
	 * @param {string} text - Text to add.
	 * @return {Track}
	 */
	addText(text) {
		var event = new MetaEvent({data: [Constants.META_TEXT_ID]});
		var stringBytes = Utils.stringToBytes(text);
		event.data = event.data.concat(Utils.numberToVariableLength(stringBytes.length)); // Size
		event.data = event.data.concat(stringBytes); // Text
		return this.addEvent(event);
	}

	/**
	 * Adds copyright to MIDI file.
	 * @param {string} text - Text of copyright line.
	 * @return {Track}
	 */
	addCopyright(text) {
		var event = new MetaEvent({data: [Constants.META_COPYRIGHT_ID]});
		var stringBytes = Utils.stringToBytes(text);
		event.data = event.data.concat(Utils.numberToVariableLength(stringBytes.length)); // Size
		event.data = event.data.concat(stringBytes); // Text
		return this.addEvent(event);
	}

	/**
	 * Adds Sequence/Track Name.
	 * @param {string} text - Text of track name.
	 * @return {Track}
	 */
	addTrackName(text) {
		var event = new MetaEvent({data: [Constants.META_TRACK_NAME_ID]});
		var stringBytes = Utils.stringToBytes(text);
		event.data = event.data.concat(Utils.numberToVariableLength(stringBytes.length)); // Size
		event.data = event.data.concat(stringBytes); // Text
		return this.addEvent(event);
	}

	/**
	 * Sets instrument name of track.
	 * @param {string} text - Name of instrument.
	 * @return {Track}
	 */
	addInstrumentName(text) {
		var event = new MetaEvent({data: [Constants.META_INSTRUMENT_NAME_ID]});
		var stringBytes = Utils.stringToBytes(text);
		event.data = event.data.concat(Utils.numberToVariableLength(stringBytes.length)); // Size
		event.data = event.data.concat(stringBytes); // Text
		return this.addEvent(event);
	}

	/**
	 * Adds marker to MIDI file.
	 * @param {string} text - Marker text.
	 * @return {Track}
	 */
	addMarker(text) {
		var event = new MetaEvent({data: [Constants.META_MARKER_ID]});
		var stringBytes = Utils.stringToBytes(text);
		event.data = event.data.concat(Utils.numberToVariableLength(stringBytes.length)); // Size
		event.data = event.data.concat(stringBytes); // Text
		return this.addEvent(event);
	}

	/**
	 * Adds cue point to MIDI file.
	 * @param {string} text - Text of cue point.
	 * @return {Track}
	 */
	addCuePoint(text) {
		var event = new MetaEvent({data: [Constants.META_CUE_POINT]});
		var stringBytes = Utils.stringToBytes(text);
		event.data = event.data.concat(Utils.numberToVariableLength(stringBytes.length)); // Size
		event.data = event.data.concat(stringBytes); // Text
		return this.addEvent(event);
	}

	/**
	 * Adds lyric to MIDI file.
	 * @param {string} lyric - Lyric text to add.
	 * @return {Track}
	 */
	addLyric(lyric) {
		var event = new MetaEvent({data: [Constants.META_LYRIC_ID]});
		var stringBytes = Utils.stringToBytes(lyric);
		event.data = event.data.concat(Utils.numberToVariableLength(stringBytes.length)); // Size
		event.data = event.data.concat(stringBytes); // Lyric
		return this.addEvent(event);
	}

	/**
	 * Channel mode messages
	 * @return {Track}
	 */
	polyModeOn() {
		var event = new NoteOnEvent({data: [0x00, 0xB0, 0x7E, 0x00]});
		return this.addEvent(event);
	}

}

export {Track};
import {toMidi} from 'tonal-midi';

/**
 * Static utility functions used throughout the library.
 */
class Utils {

	/**
	 * Gets MidiWriterJS version number.
	 * @return {string}
	 */
	static version() {
		return Constants.VERSION;
	}

	/**
	 * Convert a string to an array of bytes
	 * @param {string} string
	 * @return {array}
	 */
	static stringToBytes(string) {
		return string.split('').map(char => char.charCodeAt())
	}

	/**
	 * Checks if argument is a valid number.
	 * @param {*} n - Value to check
	 * @return {boolean}
	 */
	static isNumeric(n) {
		return !isNaN(parseFloat(n)) && isFinite(n)
	}

	/**
     * Returns the correct MIDI number for the specified pitch.
     * Uses Tonal Midi - https://github.com/danigb/tonal/tree/master/packages/midi
     * @param {(string|number)} pitch - 'C#4' or midi note code
     * @return {number}
     */
     static getPitch(pitch) {
     	return toMidi(pitch);
     }

	/**
	 * Translates number of ticks to MIDI timestamp format, returning an array of
	 * hex strings with the time values. Midi has a very particular time to express time,
	 * take a good look at the spec before ever touching this function.
	 * Thanks to https://github.com/sergi/jsmidi
	 *
	 * @param {number} ticks - Number of ticks to be translated
	 * @return {array} - Bytes that form the MIDI time value
	 */
	static numberToVariableLength(ticks) {
	    var buffer = ticks & 0x7F;

	    while (ticks = ticks >> 7) {
	        buffer <<= 8;
	        buffer |= ((ticks & 0x7F) | 0x80);
	    }

	    var bList = [];
	    while (true) {
	        bList.push(buffer & 0xff);

	        if (buffer & 0x80) buffer >>= 8
	        else { break; }
	    }

	    return bList;
	}

	/**
	 * Counts number of bytes in string
	 * @param {string} s
	 * @return {array}
	 */
	static stringByteCount(s) {
		return encodeURI(s).split(/%..|./).length - 1
	}

	/**
	 * Get an int from an array of bytes.
	 * @param {array} bytes
	 * @return {number}
	 */
	static numberFromBytes(bytes) {
		var hex = '';
		var stringResult;

		bytes.forEach(function(byte) {
			stringResult = byte.toString(16);

			// ensure string is 2 chars
			if (stringResult.length == 1) stringResult = "0" + stringResult

			hex += stringResult;
		});

		return parseInt(hex, 16);
	}

	/**
	 * Takes a number and splits it up into an array of bytes.  Can be padded by passing a number to bytesNeeded
	 * @param {number} number
	 * @param {number} bytesNeeded
	 * @return {array} - Array of bytes
	 */
	static numberToBytes(number, bytesNeeded) {
		bytesNeeded = bytesNeeded || 1;

		var hexString = number.toString(16);

		if (hexString.length & 1) { // Make sure hex string is even number of chars
			hexString = '0' + hexString;
		}

		// Split hex string into an array of two char elements
		var hexArray = hexString.match(/.{2}/g);

		// Now parse them out as integers
		hexArray = hexArray.map(item => parseInt(item, 16))

		// Prepend empty bytes if we don't have enough
		if (hexArray.length < bytesNeeded) {
			while (bytesNeeded - hexArray.length > 0) {
				hexArray.unshift(0);
			}
		}

		return hexArray;
	}

	/**	
	 * Converts value to array if needed.
	 * @param {string} value
	 * @return {array}
	 */
	static toArray(value) {
		if (Array.isArray(value)) return value;
		return [value];
	}
}

export {Utils};
class VexFlow {
	
	constructor() {
		// code...
	}

	/**
	 * Support for converting VexFlow voice into MidiWriterJS track
	 * @return MidiWritier.Track object
	 */
	trackFromVoice(voice) {
		var track = new Track();
		var wait;
		var pitches = [];

		voice.tickables.forEach(function(tickable) {
			pitches = [];

			if (tickable.noteType === 'n') {
				tickable.keys.forEach(function(key) {
					// build array of pitches
					pitches.push(this.convertPitch(key));
				});

			} else if (tickable.noteType === 'r') {
				// move on to the next tickable and use this rest as a `wait` property for the next event
				wait = this.convertDuration(tickable);
				return;
			}

			track.addEvent(new NoteEvent({pitch: pitches, duration: this.convertDuration(tickable), wait: wait}));
			
			// reset wait
			wait = 0;
		});

		return track;
	}


	/**
	 * Converts VexFlow pitch syntax to MidiWriterJS syntax
	 * @param pitch string
	 */
	convertPitch(pitch) {
		return pitch.replace('/', '');
	} 


	/**
	 * Converts VexFlow duration syntax to MidiWriterJS syntax
	 * @param note struct from VexFlow
	 */
	convertDuration(note) {
		switch (note.duration) {
			case 'w':
				return '1';
			case 'h':
				return note.isDotted() ? 'd2' : '2';
			case 'q':
				return note.isDotted() ? 'd4' : '4';
			case '8':
				return note.isDotted() ? 'd8' : '8';
		}

		return note.duration;
	};
}

export {VexFlow};
/**
 * Object that puts together tracks and provides methods for file output.
 * @param {array} tracks - An array of {Track} objects.
 * @return {Writer}
 */
class Writer {
	constructor(tracks) {
		this.data = [];

		var trackType = tracks.length > 1 ? Constants.HEADER_CHUNK_FORMAT1 : Constants.HEADER_CHUNK_FORMAT0;
		var numberOfTracks = Utils.numberToBytes(tracks.length, 2); // two bytes long

		// Header chunk
		this.data.push(new Chunk({
								type: Constants.HEADER_CHUNK_TYPE,
								data: trackType.concat(numberOfTracks, Constants.HEADER_CHUNK_DIVISION)}));

		// Track chunks
		tracks.forEach(function(track, i) {
			track.addEvent(new MetaEvent({data: Constants.META_END_OF_TRACK_ID}));
			this.data.push(track);
		}, this);
	}

	/**
	 * Builds the file into a Uint8Array
	 * @return {Uint8Array}
	 */
	buildFile() {
		var build = [];

		// Data consists of chunks which consists of data
		this.data.forEach((d) => build = build.concat(d.type, d.size, d.data));

		return new Uint8Array(build);
	}

	/**
	 * Convert file buffer to a base64 string.  Different methods depending on if browser or node.
	 * @return {string}
	 */
	base64() {
		if (typeof btoa === 'function') return btoa(String.fromCharCode.apply(null, this.buildFile()));
		return new Buffer(this.buildFile()).toString('base64');
	}

    /**
     * Get the data URI.
     * @return {string}
     */
    dataUri() {
    	return 'data:audio/midi;base64,' + this.base64();
    }

	/**
	 * Output to stdout
	 * @return {string}
	 */
    stdout() {
    	return process.stdout.write(new Buffer(this.buildFile()));
    }

	/**
	 * Save to MIDI file
	 * @param {string} filename
	 */
	saveMIDI(filename) {
		var buffer = new Buffer(this.buildFile());
		fs.writeFile(filename + '.mid', buffer, function (err) {
			if(err) return console.log(err);
		});
	}
}

export {Writer};
